import { ApplicationHttpClient } from '@app/helpers/custom-http-client';
import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BoosterQuota, Company, CompanyInviteUser } from '@app/shared/models/company';
import { TeamMember } from '@app/shared/models/teamMember';
import { HttpResult } from '@app/shared/models/http-result';
import { CountryPlan } from "@app/shared/models/country-plan";
import { Brand } from '@app/shared/models/brand';
import { AccessGroup } from '@app/shared/models/access-group';
import { Tag } from "@app/shared/models/tag";
import { Invoice } from '@app/shared/models/payment-result';
import { CompetitorQuota } from "@app/shared/models/competitor";
import { SyncStatus } from "@app/shared/models/sync-status";
import { CompanyStats } from "@app/shared/models/company-stats";
import { LocationSummary } from "@app/shared/models/location-summary";
import { AdditionalLicenseCost } from "@app/shared/models/subscription";
import { HttpParams } from "@angular/common/http";
import {CrCompany, CrCompanyObj} from "@app/shared/models/cr-company";

@Injectable()
export class CompanyService {
  private companyUrl = '/companies';

  constructor(private http: ApplicationHttpClient) { }

  public createCompany(company: Company): Observable<void> {
    const createCompanyURL: string = this.companyUrl;
    return this.http.post(createCompanyURL, company);
  }

  public invite(companyId: string, user: CompanyInviteUser): Observable<void> {
    const createCompanyURL: string =
      this.companyUrl + '/' + companyId + '/invite';
    return this.http.post(createCompanyURL, user);
  }

  public getCompanyMembers(companyId: string): Observable<TeamMember[]> {
    const createCompanyURL: string =
      this.companyUrl + '/' + companyId + '/members';
    return this.http
      .get<HttpResult>(createCompanyURL)
      .pipe(map((result) => result.data));
  }
  public getAvailablePlans(companyId: string): Observable<CountryPlan> {
    const url: string =
      this.companyUrl + '/' + companyId + '/available-plan';
    return this.http
      .get<HttpResult>(url)
      .pipe(map((result) => result.data));
  }


  public getAllTags(companyId: string): Observable<Tag[]> {
    const getMyCompaniesTagsUrl = this.companyUrl + '/' + companyId + '/tags';
    return this.http.get<HttpResult>(getMyCompaniesTagsUrl).pipe(
      map((it) => it.data));
  }

  // Note that since we are using this in a dropdown, for now we are not providing it with pagination
  public getAllCompanyBrands(companyId: string): Observable<Brand[]> {
    const brandUrl: string = this.companyUrl + '/' + companyId + '/brands';
    return this.http.get<HttpResult>(brandUrl).pipe(map((it) => it.data));
  }

  // Note that since we are using this in a dropdown, for now we are not providing it with pagination
  public getAllCompanyAccessGroups(companyId: string): Observable<AccessGroup[]> {
    const accessGroupUrl: string = this.companyUrl + '/' + companyId + '/access-groups';
    return this.http.get<HttpResult>(accessGroupUrl).pipe(map((it) => it.data));
  }

  public getCompanyInvoices(companyId: string): Observable<Invoice[]> {
    const getCompanyInvoicesURL: string =
      this.companyUrl + '/' + companyId + '/invoices';
    return this.http
      .get<HttpResult>(getCompanyInvoicesURL)
      .pipe(map((result) => result.data));
  }

  public getCompanyCompetitorsQuota(companyId: string): Observable<CompetitorQuota> {
    const getCompanyCompetitorsQuotaURL: string =
      this.companyUrl + '/' + companyId + '/competitors/quota';
    return this.http
      .get<HttpResult>(getCompanyCompetitorsQuotaURL)
      .pipe(map((result) => result.data));
  }

  public getCompanyBoosterQuota(companyId: string): Observable<BoosterQuota> {
    const getCompanyBoosterQuotaURL: string =
      this.companyUrl + '/' + companyId + '/booster/quota';
    return this.http
      .get<HttpResult>(getCompanyBoosterQuotaURL)
      .pipe(map((result) => result.data));
  }
  getSyncingLocations(companyId: string): Observable<SyncStatus> {
    const url: string = this.companyUrl + '/' + companyId + '/sync-status';
    return this.http.get<HttpResult>(url).pipe(map((it) => it.data));
  }

  getCompanyStats(companyId: string): Observable<CompanyStats> {
    const url: string =
        this.companyUrl + '/' + companyId + '/location-stats';
    return this.http
        .get<HttpResult>(url)
        .pipe(map((result) => result.data));
  }
  getCompanyLocationSummary(companyId: string): Observable<LocationSummary[]> {
    const url: string =
        this.companyUrl + '/' + companyId + '/location-summaries';
    return this.http
        .get<HttpResult>(url)
        .pipe(map((result) => result.data));
  }
  getAdditionalLicenseCost(companyId: string, quantity: number): Observable<AdditionalLicenseCost> {
    const url: string = `${this.companyUrl}/${companyId}/additional-license-cost?quantity=${quantity}`;
    return this.http
      .get<HttpResult>(url)
      .pipe(map((result) => result.data));
  }

    updatePaymentMethod(url: string, companyId: string): Observable<string> {
      const redirectUrl = `${window.location.origin}${url}`;
      const requestUrl: string = `${this.companyUrl}/${companyId}/update-payment-method`;
      return this.http
          .get<HttpResult>(requestUrl,{
            params: new HttpParams().set('redirectUrl', redirectUrl)
          })
          .pipe(map((result) => result.data));
    }

  cancelSubscription(url: string, companyId: string): Observable<string> {
    const redirectUrl = `${window.location.origin}${url}`;
    const requestUrl: string = `${this.companyUrl}/${companyId}/cancel-subscription`;
    return this.http
        .get<HttpResult>(requestUrl,{
          params: new HttpParams().set('redirectUrl', redirectUrl)
        })
        .pipe(map((result) => result.data));
  }

  getCRCompany(crRequest: CrCompanyObj): Observable<CrCompany> {
    const crCheckURL: string = `${this.companyUrl}/check-cr-number`;
    return this.http.post(crCheckURL, crRequest);
  }
}
