import { ApplicationHttpClient } from '@app/helpers/custom-http-client';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpResult } from '@app/shared/models/http-result';
import { map } from 'rxjs/operators';
import { GeoKLocation, GeokLocationPhoto } from '@app/shared/models/geo-k-location';
import { TeamMember } from '@app/shared/models/teamMember';
import { HourType } from '@app/shared/models/hour-type';
import { LocationMenuData } from "@app/shared/models/location-menu";

@Injectable()
export class LocationService {
    private locationsUrl = '/locations';

    constructor(private http: ApplicationHttpClient) {
    }

    public getLocation(id: string): Observable<GeoKLocation> {
        const getLocationUrl = this.locationsUrl + '/' + id;
        return this.http.get<HttpResult>(getLocationUrl).pipe(map((it) => it.data));
    }

    public getMenu(locationId: string): Observable<LocationMenuData> {
        return this.http
            .get<HttpResult>(this.locationsUrl + '/' + locationId + '/menu')
            .pipe(map((it) => it.data));
    }

    public updateMenu(locationId: string, locationMenuData: LocationMenuData): Observable<LocationMenuData> {
        const updateLocationUrl = this.locationsUrl + '/' + locationId + '/menu';
        return this.http
            .put<HttpResult>(updateLocationUrl, {menu: locationMenuData})
            .pipe(map((it) => it.data));
    }
    batchUpdateMenu(locationMenuData: LocationMenuData, locationIds: string[]) {
        const locationBatch = this.locationsUrl + '/menu';
        return this.http
            .put<HttpResult>(locationBatch, {
                menu: locationMenuData,
                locationIds: locationIds,
            })
            .pipe(map((it) => it.data));
    }

    public updateLocation(
        id: string,
        locationProperty: NonNullable<unknown>
    ): Observable<GeoKLocation> {
        const updateLocationUrl = this.locationsUrl + '/' + id;
        return this.http
            .put<HttpResult>(updateLocationUrl, locationProperty)
            .pipe(map((it) => it.data));
    }

    public updateLocationTags(
        id: string,
        tags: string[]
    ): Observable<GeoKLocation> {
        const updateLocationTags = this.locationsUrl + '/' + id + '/tags';
        return this.http
            .post<HttpResult>(updateLocationTags, {tagIds: tags})
            .pipe(map((it) => it.data));
    }


    public getActiveMembers(id: string): Observable<TeamMember[]> {
        const filters = [
            {
                property: 'emailVerified',
                operation: 'eq',
                value: true,
            },
        ];
        const getUsersUrl = `${
            this.locationsUrl
        }/${id}/members?filter=${JSON.stringify(filters)}`;
        return this.http.get<HttpResult>(getUsersUrl).pipe(
            map((it) => {
                return it.data;
            })
        );
    }


    batchUpdate(locationProperty: NonNullable<unknown>, locationIds: string[]) {
        const locationBatch = this.locationsUrl + '/batch-update';
        return this.http
            .post<HttpResult>(locationBatch, {
                locationData: locationProperty,
                locationIds: locationIds,
            })
            .pipe(map((it) => it.data));
    }

    exportCSV(locationIds: string[]): Observable<Blob | null> {
        const exportCSVUrl = this.locationsUrl + '/export-excel';
        return this.http
            .postBlub(exportCSVUrl, {locationIds})
            .pipe(map((it) => it.body));
    }

    exportActivitiesCSV(locationIds: string[]): Observable<Blob | null> {
        const exportActivitiesCSVUrl = '/location-changes/export-excel';
        return this.http
            .postBlub(exportActivitiesCSVUrl , {locationIds})
            .pipe(map((it) => it.body));
    }

    getHourTypes(id: string): Observable<HourType[]> {
        const getHourTypesUrl = `${this.locationsUrl}/${id}/hour-types`;
        return this.http.get<HttpResult>(getHourTypesUrl).pipe(
            map((it) => {
                return it.data;
            })
        );
    }

    getMedia(id: string, category: string): Observable<GeokLocationPhoto[]> {
        const getMediaUrl = `${this.locationsUrl}/${id}/media?category=${category}`;
        return this.http.get<HttpResult>(getMediaUrl).pipe(
            map((it) => {
                return it.data;
            })
        );
    }

    postMedia(locationId: string, url: string, category: string): Observable<GeokLocationPhoto> {
        const postMediaUrl = `${this.locationsUrl}/${locationId}/media`;
        return this.http.post<HttpResult>(postMediaUrl, {
            category: category,
            url: url,
        })
            .pipe(map((it) => it.data));
    }

    deleteMedia(locationId: string, mediaId: string): Observable<void> {
        const deleteMediaUrl = `${this.locationsUrl}/${locationId}/media/${mediaId}`;
        return this.http.delete<HttpResult>(deleteMediaUrl)
            .pipe(map((it) => it.data));
    }

    updateTagLocations(tagId: string, locationIds: string[]): Observable<string> {
        const updateTagLocationsUrl = `${this.locationsUrl}/tags`;
        return this.http.post<HttpResult>(updateTagLocationsUrl, {
            locationIds: locationIds,
            tagId: tagId,
        }).pipe(map(it => it.data));
    }
}
