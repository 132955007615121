import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { EMPTY, Observable, of, switchMap } from "rxjs";
import { UserService } from '@app/shared/services/user.service';
import { AuthFacade } from "@app/shared/store/auth/auth.facade";
import { UserAccessRolesEnum } from "@app/shared/models/user/user-access";
import { map } from "rxjs/operators";
import { PlanSubscription } from "@app/shared/models/user/plan-subscription";
import { DateService } from "@app/shared/services/date.service";

@Injectable({
    providedIn: 'root'
})
export class RoleAccessResolver {

    constructor(private userService: UserService,
                private router: Router,
                private dateService: DateService,
                private authFacade: AuthFacade) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<UserAccessRolesEnum[]> {
        const accesses = route.data['access'];
        return this.authFacade.selectIsSubscriptionLoaded$.pipe(
            switchMap(()=> this.authFacade.selectCompanySubscription$),
            switchMap((companySubscription: PlanSubscription) => {
                if (companySubscription.id) {
                    return of(companySubscription);
                } else {
                    this.authFacade.getSubscription();
                    return EMPTY;
                }
            }),
            switchMap((companySubscription: PlanSubscription) => {
                return this.authFacade.selectUserRoles.pipe(
                    map((userRoles: UserAccessRolesEnum[]) => {
                        return [userRoles, companySubscription] as [UserAccessRolesEnum[], PlanSubscription]
                    })
                )
            }),
            map((result: [UserAccessRolesEnum[], PlanSubscription]) => {
                const planSubscription: PlanSubscription = result[1];
                const userRoles: UserAccessRolesEnum[] = result[0];
                const isSubscriptionExpired =
                    this.dateService.isTodayGreaterThanDate(new Date(planSubscription.expiryDate));
                const haveAccess: boolean = userRoles.some((element: UserAccessRolesEnum) => accesses.includes(element));
                if (isSubscriptionExpired) {
                    this.router.navigate(['onboarding/handle-subscription-expiry']);
                    return [];
                }
                if (haveAccess && !isSubscriptionExpired) {
                    return userRoles;
                }
                this.router.navigate(['/dashboards/no-permission']);
                return [];

            })
        );
    }


}

